@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=JetBrains+Mono:ital,wght@0,100..800;1,100..800&display=swap');

/* @layer base {
  * {
    @apply border-border;
  }
  body {
    @apply font-sans antialiased bg-background text-foreground;
  }
} */


@font-face {
  font-family: 'Mendl Serif';
  src: url('./fonts/mendl/MendlSerif_W_DuskLt.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Arizona';
  src: url('./fonts/arizona/ABCArizonaSans-Light.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

html,
body {
  padding: 0px;
  margin: 0px;
  position: relative;
  width: 100%;
  height: 100%;
  font-family: 'Arizona', sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  color: #18181b;
  background-color: black;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  position: relative;
  width: 100%;
  height: 100%;
}

@layer components {
  .button-style-regular {
    @apply bg-[#7160E9] border border-white/[0.16] relative;
  }
  
  .button-style-regular::before {
    @apply content-[''] absolute inset-0;
    background: linear-gradient(247.26deg, rgba(245, 196, 255, 0) 35.23%, rgba(245, 196, 255, 0.5) 100%);
  }
}

